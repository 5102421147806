import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

const NotFoundPage = () => (
  <Layout>
    <div className='page'>
      <SEO title='404: Not found' />
      <section className='my-5 py-5 container'>
        <h1 className='font-weight-bold text-primary'>NOT FOUND</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      </section>
    </div>
  </Layout>
);

export default NotFoundPage;
